// import S3uploadService from 'src/app/shared/services/s3upload.service';

var viewerConfig = {
  /* Disable the left-hand panel */
  showLeftHandPanel: false /* Default value is true */,
};

/* Variable for holding reference of viewer API */
var viewerApis;

/* Helper function to render the file using PDF Embed API. */
function previewFile(filePromise, fileName, pdfembedapi) {
  /* Initialize the AdobeDC View object */
  var adobeDCView = new AdobeDC.View({
    /* Pass your registered client id */
    clientId: pdfembedapi,
    /* Pass the div id in which PDF should be rendered */
    divId: "adobe-dc-view",
  });

  /* Invoke the file preview API on Adobe DC View object */
  var previewFilePromise = adobeDCView.previewFile(
    {
      /* Pass information on how to access the file */
      content: {
        /* Pass file promise which resolves to arrayBuffer */
        promise: filePromise,
      },
      /* Pass meta data of file */
      metaData: {
        /* file name */
        fileName: fileName,
      },
    },
    viewerConfig
  );

  /* Use the getAPIs() interface to invoke the viewer APIs */
  previewFilePromise
    .then(function (adobeViewer) {
      adobeViewer.getAPIs().then(function (apis) {
        viewerApis = apis;
        /* Fetch and display the attachments available in the PDF. */
        getAttachments();
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

var folderPath;
var dataVal;
/* Helper function to listen for file upload and
 * creating Promise which resolve to ArrayBuffer of file data.
 **/
function listenForFileUpload(url, fileName, folderName, pdfembedapi, data) {
  folderPath = folderName;
  dataVal = data;
  fetch(url)
    .then((res) => res.blob()) // Gets the response and returns it as a blob
    .then((blob) => {
      // saveAs(blob, "sample.pdf");
      var reader = new FileReader();
      reader.onloadend = function (e) {
        var filePromise = Promise.resolve(e.target.result);
        previewFile(filePromise, fileName, pdfembedapi);
      };
      reader.readAsArrayBuffer(blob);
    });
}

/* Function to get the list of attachments. */
var getAttachments = function () {
  // var attachmentPaneItem = document.getElementById('attachment-pane');
  viewerApis
    .getAttachmentAPIs()
    .getAttachments()
    .then(function (attachments) {
      if (attachments.length === 0) {
        /* Check when there is no attachment. */
        console.log("No attachment available in the PDF");
        // var label = document.createElement("label");
        // label.innerText = "No attachment available in the PDF";
        // label.style.color = "#777";
        // label.style.fontFamily = "Lato, sans-serif;";
        // label.style.fontWeight = "bold";
        // attachmentPaneItem.appendChild(label);
      } else {
        /* Create the list of attachments. */
        // var list = document.createElement("ul");
        attachments.forEach(function (element) {
          // createAttachmentList(list, element);
          getAttachmentBuffer(element.name, element.mimeType);
        });
        // attachmentPaneItem.appendChild(list);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

/* Download the attachment from the PDF. */
var getAttachmentBuffer = function (attachmentName, mimeType) {
  viewerApis
    .getAttachmentAPIs()
    .getAttachmentBuffer(attachmentName)
    .then(function (res) {
      var blob = new Blob([res.buffer], { type: mimeType });
      uploadFile(blob, attachmentName);
    })
    .catch(function (error) {
      console.log(error);
    });
};

function callAngularFunction(file, name, folderPath) {
  window["app-edit-company"].zone.run(() => {
    window["app-edit-company"].loadAngularFunction(file, name, folderPath);
  });
}

var uploadFile = function (file, name) {
  try {
    // console.log(file +"&& "+name)
    callAngularFunction(file, name);
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/pdf");
    // var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: file,
    //     redirect: 'follow'
    // };

    // fetch("http://localhost:3000/v1/company/upload/60793458adc3313240af2722", requestOptions)
    //     .then(response => {
    //         response.text()
    //     })
    //     .then(result => {
    //         console.log(result)
    //     })
    //     .catch(error => {
    //         console.log('error', error)

    //     });

    //     console.log(file)
    //   S3uploadService.uploadFile(file, folderPath).then(
    //         (data) => {
    //             console.log(data)
    //         });
    //hit node js api here to upload file to server
  } catch (error) {
    console.log("error", error);
  }
};
